import React from 'react'
import { Image, Card } from '@nextui-org/react'
import { useRouter } from 'next/router'

const AIImage = ({ image }) => {
  const router = useRouter()

  const path = router.query.keyword ? router.pathname : router.asPath

  return (
    <div className="my-2 px-2">
      <Card className="group h-auto w-full" isPressable={image?.image_url} onClick={() => router.push(`${path}?image_id=${image?.id}`, undefined, { shallow: true })} disableRipple>
        <Image src={image?.image_url || '/nodata.png'} alt="" isBlurred isZoomed />
      </Card>
    </div>
  )
}

export default AIImage
