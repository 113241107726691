import React, { useCallback, useState } from 'react'
import produce from 'immer'
import { useRouter } from 'next/router'
import { Card, Spinner } from '@nextui-org/react'
import AIImages from '@/components/HomePage/ImageList'
import TextToImageForm from '@/components/Image/AIImageForm'
import { getToken } from '@/utils/request'
import { getCreatedImages } from '@/swr/useProfile'
import { useModel } from '@/plugin-model/useModel'

const LoadingComponent = () => {
  return (
    <div className="flex h-full items-center justify-center">
      <Spinner color="primary" size="lg" />
    </div>
  )
}

let requestingPage = 0
const Creation = () => {
  const router = useRouter()
  const pageSize = 20
  const { view } = useModel('view')
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(true)
  const [images, setImages] = useState(undefined)

  const fetchImages = useCallback(async () => {
    try {
      if (requestingPage >= page) {
        return
      }
      requestingPage = page
      const res = await getCreatedImages(page, pageSize)
      setHasMore(res.length >= pageSize)
      setImages(page === 1 ? res.images : produce((draft) => draft.concat(res.images)))
      setPage(page + 1)
    } catch (error) {
      console.error(error)
    }
  }, [page])

  const onChange = useCallback(() => {
    setHasMore(false)
    requestingPage = 0
    fetchImages()
  }, [fetchImages])

  return (
    <div className="flex h-full flex-col lg:flex-row">
      <div className="h-content flex-grow overflow-hidden lg:flex">
        <div className="h-content w-full max-w-full overflow-auto border-r border-solid border-divider lg:w-[400px]">
          <TextToImageForm />
        </div>
      </div>
      <div className={`m-auto h-content w-full overflow-auto p-2 lg:block lg:w-content ${getToken() ? 'lg:block' : 'hidden'}`}>
        <div className="grid grid-cols-2 gap-4 px-2 pb-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6">
          {view.imageLoading &&
            [...Array(view.promptConfig.num_outputs).keys()]
              .map((i) => i + 1)
              .map((v) => (
                <Card key={v} isPressable className="flex aspect-square items-center justify-center bg-zinc-700">
                  <LoadingComponent />
                </Card>
              ))}
          {view.imageResults.map((image, i) => {
            return (
              <Card
                key={+i}
                isPressable
                onClick={() => router.push(`${router.pathname}?image_id=${image?.id}`, undefined, { shallow: true })}
                className="flex aspect-square h-full w-full items-center justify-center overflow-hidden"
              >
                <div
                  className="h-full max-h-full w-full max-w-full"
                  style={{
                    backgroundImage: `url(${image.cdn_img_url})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                  }}
                />
              </Card>
            )
          })}
        </div>
        <AIImages images={images} setImages={setImages} onChange={onChange} hasMore={hasMore} />
      </div>
    </div>
  )
}

export default Creation
