import React, { useCallback, useEffect } from 'react'
import { Slider, Switch, SelectItem, Select, Input, Textarea } from '@nextui-org/react'
import { useForm } from 'react-hook-form'
import Button from '@/components/Button'
import { FORMATS, SHAPES } from '@/consts'
import { notify } from '@/utils/crypto'
import { useProfile } from '@/swr/useProfile'
import { getToken } from '@/utils/request'
import { createTextToImage, fetchText2imgTaskStatus } from '@/services/ai_images'
import { useModel } from '@/plugin-model/useModel'
import IconFont from '../IconFonts'

const DescForm = () => {
  const { view, viewSet } = useModel('view')
  const { data: user } = useProfile()

  const generateRandomSeed = useCallback(() => {
    const randomSeed = Math.floor(Math.random() * 1000000000)
    viewSet('promptConfig', { ...view.promptConfig, seed: randomSeed })
  }, [view.promptConfig, viewSet])

  useEffect(() => {
    if (view.promptConfig.seed === 0) generateRandomSeed()
  }, [generateRandomSeed, view.promptConfig.seed])

  const { handleSubmit } = useForm({
    mode: 'onBlur',
  })

  return (
    <div className="relative h-full">
      <form
        className="h-full overflow-auto px-6"
        onSubmit={handleSubmit(async () => {
          if (getToken()) {
            viewSet('imageLoading', true)
            createTextToImage(
              view.promptConfig.prompt,
              view.promptConfig.aspect_ratio,
              view.promptConfig.num_outputs,
              view.promptConfig.seed,
              view.promptConfig.output_format,
              view.promptConfig.output_quality,
              view.promptConfig.disable_safety_checker
            )
              .then((res) => {
                if (res.code === 200) {
                  // 开始轮询tasks状态
                  const task_id = res.data.task_id
                  const intervalId = setInterval(() => {
                    fetchText2imgTaskStatus(task_id).then((resp) => {
                      if (resp.status === 'SUCCESS') {
                        // 如果任务完成或满足停止条件，则清除定时器
                        clearInterval(intervalId)
                        viewSet('imageLoading', false)
                        viewSet('imageResults', resp.data.image_url_list.concat(view.imageResults))
                      } else if (resp.code !== 200) {
                        clearInterval(intervalId)
                        viewSet('imageLoading', false)
                        notify(resp.data.message, 'warning')
                      }
                    })
                  }, 3000)
                } else {
                  notify(res.data.message, 'warning')
                  viewSet('imageLoading', false)
                }
              })
              .catch((err) => {
                console.error(err)
                notify('生成图片失败', 'error')
                viewSet('imageLoading', false)
              })
              .finally(generateRandomSeed())
          } else {
            generateRandomSeed()
          }
        })}
      >
        <div className="sticky top-0 z-20 bg-background py-6">
          <div className="flex items-center justify-around rounded-lg border border-solid border-divider py-2">
            <div className="flex items-center gap-2 text-sm">
              <IconFont icon="icon-credit" />
              <div>剩余积分：{user?.credit}</div>
            </div>
            <div className="flex items-center gap-2 text-sm">
              <IconFont icon="icon-mj" />
              本次消耗积分：10
            </div>
          </div>
        </div>
        <Textarea
          isRequired
          placeholder="输入AI关键词，探索无限可能"
          label={<span className="text-base text-foreground">提示词</span>}
          labelPlacement="outside"
          maxRows={10}
          minRows={5}
          disableAnimation
          description={
            <div className="flex items-center justify-between">
              <div>您可以使用单个单词或完整的句子。</div>
            </div>
          }
          variant="bordered"
          value={view.promptConfig.prompt}
          classNames={{
            mainWrapper: 'h-full justify-center',
            input: 'text-small',
            inputWrapper: 'h-full font-normal',
          }}
          onChange={(e) => viewSet('promptConfig', { ...view.promptConfig, prompt: e.target.value })}
        />
        <Slider
          className="pt-6"
          label={<div className="text-base text-foreground">生成图片数量</div>}
          minValue={1}
          size="sm"
          value={view.promptConfig.num_outputs}
          showSteps
          marks={[
            { key: 1, value: 1 },
            { key: 2, value: 2 },
            { key: 3, value: 3 },
            { key: 4, value: 4 },
          ]}
          maxValue={4}
          onChange={(v) => viewSet('promptConfig', { ...view.promptConfig, num_outputs: v })}
        />
        <Select
          description="生成图像的比例。横向、方形或水平。"
          defaultSelectedKeys={[view.promptConfig.aspect_ratio]}
          label={<span className="text-base text-foreground">图片比例</span>}
          labelPlacement="outside"
          className="pt-6"
          onChange={(e) => viewSet('promptConfig', { ...view.promptConfig, aspect_ratio: e.target.value })}
        >
          {SHAPES.map((v) => (
            <SelectItem key={v}>{v}</SelectItem>
          ))}
        </Select>
        <Select
          description="生成图像的格式。"
          defaultSelectedKeys={[view.promptConfig.output_format]}
          label={<span className="text-base text-foreground">图片格式</span>}
          labelPlacement="outside"
          className="pt-6"
          onChange={(e) => viewSet('promptConfig', { ...view.promptConfig, output_format: e.target.value })}
        >
          {FORMATS.map((v) => (
            <SelectItem key={v} value={v}>
              {v}
            </SelectItem>
          ))}
        </Select>
        <Input
          label={<div className="text-base text-foreground">随机种子</div>}
          variant="bordered"
          labelPlacement="outside"
          placeholder={view.promptConfig.seed}
          value={view.promptConfig.seed}
          description="随机生成Seed生成更多不同风格的图片"
          className="pt-6"
          onChange={(v) => viewSet('promptConfig', { ...view.promptConfig, seed: v })}
        />
        <Slider
          className="pt-6"
          label={<div className="text-base text-foreground">图片质量</div>}
          minValue={10}
          size="sm"
          value={view.promptConfig.output_quality}
          maxValue={100}
          onChange={(v) => viewSet('promptConfig', { ...view.promptConfig, output_quality: v })}
        />
        <Switch
          className="py-6"
          defaultSelected={view.promptConfig.disable_safety_checker}
          onValueChange={(v) => viewSet('promptConfig', { ...view.promptConfig, disable_safety_checker: v })}
        >
          安全检测
        </Switch>
        <div className="sticky inset-x-0 bottom-0 z-20 w-full bg-background">
          <div className="w-full py-6">
            <Button
              type="submit"
              color="primary"
              variant="bordered"
              size="large"
              className="w-full"
              isLoading={view.imageLoading}
              spinner={
                <svg className="h-5 w-5 animate-spin text-current" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                  <path
                    className="opacity-75"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    fill="currentColor"
                  />
                </svg>
              }
              isDisabled={view.imageLoading}
            >
              立即生成
            </Button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default DescForm
